<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <div class="card card-body">
              Configurar NFe
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
export default {
  name: "Configurar NF-e",
  page: {
    title: "Configurar NF-e",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {

  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>